<template>
    <div class='grid'>
        <div class='col-12'>
            <div class='card'>
                <div class='flex'>
                    <h5>Parça - Listagem das Parcerias</h5>
                </div>

                <tabela :data='lista' :globalFilterFields="[
                        'numeroAcordo',
                        'unidadeRegional',
                        'razaoSocial',
                        'evento',
                        'dataInicioParceria',
                        'dataFimParceria',
                        'nomeRepresentanteLegal',
                        'valorAporteSebrae',
                        'valorContrapartidaFinanceira',
                        'valorContrapartidaEconomica',
                        'valorReceita',
                        'statusText',
                    ]" dataKey='Id' stateKey='dt-parcerias'>
                    <template #botoes>
                        <!-- <CadParceria @atualizarLista="listar()" :idPessoa="idPessoa" :idParceria="0" v-if="$temAcessoView('MAPAPARC-PAR-01') && !externo"></CadParceria> -->

                        <Button v-if="$temAcessoView('MAPAPARC-PAR-01') && !externo" :idParceria='0'
                                :idPessoa='idPessoa'
                                class='ml-2' icon='pi pi-plus'
                                label='Adicionar'
                                @click="$router.push({name: 'MapaParceiros_Parceria_Formulario_DadosGerais'})" />
                    </template>

                    <template #conteudo>
                        <Column headerStyle='width: 100%'>
                            <template #body='slotProps'>
                                <font-awesome-icon class='icon-button p-text-primary mr-2' icon='info-circle'
                                                   size='lg'
                                                   title='Detalhar'
                                                   @click="$router.push({ name: 'MapaParceiros_Parceria_Detalhar', params: { id: slotProps.data.id }})"/>
                                <font-awesome-icon v-if="$temAcessoView('MAPAPARC-PAR-04') || slotProps.data.status === 0"
                                                   class='icon-button p-text-info'
                                                   icon='pen' size='lg' title='Editar'
                                                   @click="$router.push({ name: 'MapaParceiros_Parceria_Formulario_DadosGerais', params: { id: slotProps.data.id } })" />
                                <AssinarAcordoDeParceria :parceria='slotProps.data' @onSuccess='listar()'/>
                                <VerificarStatusDasAssinaturas :parceria='slotProps.data' @atualizarLista="listar()"/>
                                <!-- <CadParceria @atualizarLista="listar()" :idPessoa="idPessoa" :idParceria="slotProps.data.id" v-if="$temAcessoView('MAPAPARC-PAR-02') && !externo && (slotProps.data.status != 3 && slotProps.data.status != 4 && slotProps.data.status != 2)" ></CadParceria> -->
                                <BtnVisualizarPDFParceria
                                    :idParceria='slotProps.data.id'></BtnVisualizarPDFParceria>
                                <font-awesome-icon v-if='slotProps.data.status !== 4'
                                                   class='icon-button p-text-success ml-2' icon='file-pdf'
                                                   size='lg' title='Download Pdf'
                                                   @click='imprimirPdf(slotProps.data.id, slotProps.data.numeroAcordo)' />
                                <font-awesome-icon
                                    v-if="$temAcessoView('MAPAPARC-PAR-03') && !externo && (slotProps.data.status !== 3 && slotProps.data.status !== 4)"
                                    class='icon-button ml-2'
                                    icon='trash-alt'
                                    size='lg' style='color: red' title='Deletar'
                                    @click='confirmaDelete(slotProps.data.id)' />
                                <DialogComentariosStatusParceria v-if='slotProps.data.status === 2'
                                                                 :parceria='slotProps.data'
                                                                 titulo='Comentários sobre o cancelamento'
                                                                 @onSuccess='listar()' />
                                <DialogComentariosStatusParceria v-if='slotProps.data.status === 3'
                                                                 :parceria='slotProps.data'
                                                                 isLicaoAprendida
                                                                 titulo='Conclusões Finais' @onSuccess='listar()' />
                                <DialogComentariosStatusParceria v-if='slotProps.data.status === 4'
                                                                 :parceria='slotProps.data'
                                                                 titulo='Comentários sobre a suspensão'
                                                                 @onSuccess='listar()' />
                            </template>
                        </Column>
                        <Column :sortable='true' field='numeroAcordo' header='Nº Acordo'>
                            <template #body='slotProps'>
                                {{ slotProps.data.numeroAcordo }}
                            </template>
                        </Column>

                        <Column :sortable='true' field='unidadeRegional' header='Unidade'>
                            <template #body='slotProps'>
                                {{ slotProps.data.unidadeRegional }}
                            </template>
                        </Column>

                        <Column v-if='!idPessoa > 0' :sortable='true' field='razaoSocial' header='Nome Parceiro'>
                            <template #body='slotProps'>
                                {{ slotProps.data.razaoSocial }}
                            </template>
                        </Column>

                        <Column :sortable='true' field='evento' header='Evento'>
                            <template #body='slotProps'>
                                {{ slotProps.data.evento }}
                            </template>
                        </Column>

                        <Column :sortable='true' field='dataInicioParceriaFormatada' header='Início Parceria'>
                            <template #body='slotProps'>
                                {{ slotProps.data.dataInicioParceriaFormatada }}
                            </template>
                        </Column>

                        <Column :sortable='true' field='dataFimParceriaFormatada' header='Fim Parceria'>
                            <template #body='slotProps'>
                                {{ slotProps.data.dataFimParceriaFormatada }}
                            </template>
                        </Column>

                        <Column :sortable='true' field='nomeRepresentanteLegal' header='Gestor'>
                            <template #body='slotProps'>
                                {{ slotProps.data.nomeRepresentanteLegal }}
                            </template>
                        </Column>

                        <Column v-if='!idPessoa > 0' :sortable='true' field='valorAporteSebrae'
                                header='Valor do SEBRAE'>
                            <template #body='slotProps'>
                                <span>{{ $numeral(slotProps.data.valorAporteSebrae).format('$0,0.00') }}</span>
                            </template>
                        </Column>

                        <Column :sortable='true' field='valorContrapartidaFinanceira' header='Cont. Fin.'>
                            <template #body='slotProps'>
                                <span>{{ $numeral(slotProps.data.valorContrapartidaFinanceira).format('$0,0.00')
                                    }}</span>
                            </template>
                        </Column>

                        <Column :sortable='true' field='valorContrapartidaEconomica' header='Cont. Econ.'>
                            <template #body='slotProps'>
                                <span>{{ $numeral(slotProps.data.valorContrapartidaEconomica).format('$0,0.00')
                                    }}</span>
                            </template>
                        </Column>
                        <Column :sortable='true' field='receita' header='Receita'>
                            <template #body='slotProps'>
                                <span>{{ $numeral(slotProps.data.valorReceita).format('$0,0.00') }}</span>
                            </template>
                        </Column>
                        <Column :sortable='true' field='statusText' header='Status' headerStyle='width: 10em'>
                            <template #body='slotProps'>
                                <status :status='slotProps.data.statusDescricao'></status>
                            </template>
                        </Column>
                    </template>
                </tabela>
            </div>
        </div>
    </div>
    <Dialog v-model:visible='showDialogExcluir' :modal='true' :style="{ width: '350px' }" header='Confirmação'>
        <div class='confirmation-content'>
            <i class='pi pi-question-circle mr-3' style='font-size: 2rem' />
            <span>Confirma Exclusão?</span>
        </div>
        <template #footer>
            <Button class='p-button-text' icon='pi pi-times' label='Não' @click='closeConfirmation(false)' />
            <Button autofocus class='p-button-text' icon='pi pi-check' label='Sim'
                    @click='closeConfirmation(true)' />
        </template>
    </Dialog>
    <router-view></router-view>
</template>

<script>
import Services from '../../service.js';
import BtnVisualizarPDFParceria from './BtnVisualizarPDFParceria.vue';
import DialogComentariosStatusParceria from '../detalhes/DialogComentariosStatusParceria.vue';
import AssinarAcordoDeParceria from '../detalhes/AssinarAcordoDeParceria.vue';
import VerificarStatusDasAssinaturas from '../detalhes/VerificarStatusDasAssinaturas.vue';

export default {
    components: {
        BtnVisualizarPDFParceria,
        DialogComentariosStatusParceria,
        AssinarAcordoDeParceria,
        VerificarStatusDasAssinaturas,
    },

    props: {
        idPessoa: {
            type: Number,
        },
        filtroParceria: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            lista: [],
            showDialogExcluir: false,
            idExclusao: 0,
            visualizar: false,
            externo: false,
        };
    },

    methods: {
        imprimirPdf(id, numeroAcordo) {
            this.$store.dispatch('addRequest');
            Services.BaixarDocumentoAssinadoOuDocumentoOriginal(id).then((response) => {
                if (response) {
                    this.$download(response, `Acordo de Parceria - ${numeroAcordo} - ${this.$moment().format('DDMMYYYY')}.pdf`);
                }
                this.$store.dispatch('removeRequest');
            });
        },

        listar() {
            if (this.idPessoa > 0) {
                this.$store.dispatch('addRequest');
                Services.SelectAllParceriasByIdParceiro(this.idPessoa).then((response) => {
                    if (response && response.success) {
                        this.lista = response.data;
                    } else {
                        this.lista = null;
                    }
                    this.$store.dispatch('removeRequest');
                });
            } else {
                this.$store.dispatch('addRequest');
                var filtro;
                if (this.filtroParceria == null) {
                    filtro = {
                        dataExecussaoInicial: '',
                        valorTotalAcordoAte: '',
                        valorTotalAcordoDe: '',
                        dataInicioParceria: '',
                        dataExecussaoFinal: '',
                        unidadeRegionalId: '',
                        contraPartidaAte: '',
                        aporteSebraeAte: '',
                        contraPartidaDe: '',
                        dataFimParceria: '',
                        aporteSebraeDe: '',
                        parceiroId: '',
                        evento: '',
                        numeroAcordo: '',
                        status: '',
                        tags: '',
                        gerenteAnalistaId: '',
                        filtroFinanceiro: false,
                        eixo: '',
                        subEixo: '',
                    };
                } else {
                    filtro = {
                        dataExecussaoInicial: this.filtroParceria.dataExecussaoInicial ? this.$moment(this.filtroParceria.dataExecussaoInicial).format('YYYY-MM-DD') : '',
                        dataInicioParceria: this.filtroParceria.dataInicioParceria ? this.$moment(this.filtroParceria.dataInicioParceria).format('YYYY-MM-DD') : '',
                        dataExecussaoFinal: this.filtroParceria.dataExecussaoFinal ? this.$moment(this.filtroParceria.dataExecussaoFinal).format('YYYY-MM-DD') : '',
                        dataFimParceria: this.filtroParceria.dataFimParceria ? this.$moment(this.filtroParceria.dataFimParceria).format('YYYY-MM-DD') : '',
                        unidadeRegionalId: this.filtroParceria.unidadeRegionalId,
                        gerenteAnalistaId: this.filtroParceria.gerenteAnalistaId,
                        valorTotalAcordoAte: this.filtroParceria.valorTotalAcordoAte,
                        valorTotalAcordoDe: this.filtroParceria.valorTotalAcordoDe,
                        contraPartidaAte: this.filtroParceria.contraPartidaAte,
                        aporteSebraeAte: this.filtroParceria.aporteSebraeAte,
                        contraPartidaDe: this.filtroParceria.contraPartidaDe,
                        aporteSebraeDe: this.filtroParceria.aporteSebraeDe,
                        numeroAcordo: this.filtroParceria.numeroAcordo,
                        parceiroId: this.filtroParceria.parceiroId,
                        evento: this.filtroParceria.evento,
                        status: this.filtroParceria.status,
                        tags: this.filtroParceria.tags,
                        filtroFinanceiro: true,
                        eixo: this.filtroParceria.eixo,
                        subEixo: this.filtroParceria.subEixo,
                    };
                }
                Services.SelectAllParcerias(filtro).then((response) => {
                    if (response && response.success) {
                        this.lista = response.data.parceriaSimplificada;
                        if (this.filtroParceria != null) {
                            var relatorio = {
                                totalParceria: response.data.totalParceria,
                                totalAporteSebrae: response.data.totalAporteSebrae,
                                totalContraPartidaFinanceira: response.data.totalContraPartidaFinanceira,
                                totalReceita: response.data.totalReceita,
                            };
                            this.$emit('enviarRelatorio', relatorio);
                        }
                    } else {
                        this.lista = null;
                    }
                    this.$store.dispatch('removeRequest');
                });
            }
        },
        confirmaDelete(id) {
            this.showDialogExcluir = true;
            this.idExclusao = id;
        },

        closeConfirmation(confirmacao) {
            if (confirmacao) {
                this.deletar();
            }
            this.showDialogExcluir = false;
        },

        deletar() {
            this.$store.dispatch('addRequest');
            Services.DeletarParceria(this.idExclusao).then((response) => {
                if (response && response.success) {
                    this.listar();
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    mounted() {
        if (this.filtroParceria != null) {
            this.externo = true;
        }
        this.listar();
    },
};
</script>