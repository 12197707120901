<template>
    <font-awesome-icon
        icon="pen"
        @click="showDialog()"
        size="md"
        style="color: rgb(66, 181, 248)"
        class="icon-button mr-2"
        title="Alterar assinatura"
        v-if="assinatura.status === 'PENDENTE' || assinatura.status === 'REJEITADO'"
    />
    <Dialog v-model:visible="display" no-close-on-backdrop modal :style="{ width: '400px' }" :maximizable="false" :closable="false">
        <template #header>
            <div class="titleWrapper">
                <h3>Alterar assinatura</h3>
            </div>
        </template>
        <div class="grid p-fluid">
            <div class="col-4">
                <strong> Cargo: </strong>
            </div>
            <div class="col-8">
                {{ assinatura.cargo }}
            </div>
            <div class="col-4">
                <strong> Status: </strong>
            </div>
            <div class="col-8">
                {{ assinatura.status }}
            </div>
            <div class="col-4">
                <strong> Email: </strong>
            </div>
            <div class="col-8">
                {{ assinatura.email }}
            </div>
            <div class="col-4">
                <strong> Novo Email: </strong>
            </div>
            <div class="col-8">
                <BInput type="email" name="novo-email" v-model="novoEmail" />
            </div>
        </div>
        <template #footer>
            <Button label="Fechar" class="p-button-text" @click="closeDialog()" />
            <Button label="Alterar Assinatura" @click="alterarAssinatura()" :disabled="!podeAlterar" />
        </template>
    </Dialog>
</template>
<script>
import Services from '../../service.js';
export default {
    props: {
        assinatura: {
            type: Object,
        },
    },
    components: {},
    setup() {},
    data() {
        return {
            display: false,
            novoEmail: '',
            podeAlterar: false,
        };
    },
    methods: {
        resetarComponente() {
            this.novoEmail = '';
            this.podeAlterar = false;
        },
        showDialog() {
            this.display = true;
            this.resetarComponente();
        },
        closeDialog() {
            this.display = false;
            this.resetarComponente();
        },
        async alterarAssinatura() {
            this.$store.dispatch('addRequest');
        try {
            await Services.AlterarAssinatura(this.assinatura.id, this.novoEmail);
            this.$emit('atualizado');
            this.closeDialog();
        } catch (error) {
            console.error(error);
        } finally {
            this.$store.dispatch('removeRequest');
        }
        },
    },
    watch: {
        novoEmail() {
            this.podeAlterar = this.novoEmail && this.$validarEmail(this.novoEmail) && this.novoEmail !== this.assinatura.email;
        },
    },
};
</script>
<style>
.two-columns-card {
    display: flex;
    flex-direction: row;
}
.first-column {
    flex: 9;
}
.second-column {
    align-self: center;
    flex: 1;
}
.green {
    color: yellowgreen;
}
.red {
    color: red;
}
.yellow {
    color: orange;
}
.titleWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
</style>