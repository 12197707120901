<template>
    <font-awesome-icon
        icon="signature"
        @click="showDialog()"
        size="lg"
        style="color: black"
        class="icon-button"
        title="Verificar status das assinaturas"
        v-if="parceria.status === 6 || parceria.status === 9 || parceria.status === 7 || parceria.status === 8 || parceria.status === 1 || parceria.status === 3"
    />
    <Dialog v-model:visible="display" no-close-on-backdrop modal :style="{ width: '500px' }" :maximizable="false" :closable="false">
        <template #header>
            <div class="titleWrapper">
                <h3>Verificar status das assinaturas</h3>
                <BtnRefresh @click="rechecarStatusAssinaturas()" />
            </div>
        </template>
        <div class="grid p-fluid">
            <div class="col-3">
                <strong> Nº do Acordo: </strong>
            </div>
            <div class="col-9">
                {{ parceria.numeroAcordo }}
            </div>
            <div class="col-3">
                <strong> Assinaturas: </strong>
            </div>
            <div class="col-9">
                <div v-for="assinatura in assinaturas" :key="assinatura.id" class="card two-columns-card">
                    <div class="first-column">
                        <div class="card-title">
                            <strong>{{ assinatura.cargo }}</strong>
                        </div>
                        <div v-if="!!assinatura.nome">{{ assinatura.nome }}</div>
                        <div class="email-wrapper">
                            {{ assinatura.email }} 
                            <DialogAlterarAssinatura :assinatura="assinatura" @atualizado="rechecarStatusAssinaturas()" />
                        </div>
                    </div>
                    <div class="second-column">
                        <i class="pi pi-thumbs-up green" v-if="assinatura.status === 'ASSINADO'" v-tooltip.top="'Assinado em ' + $moment(assinatura.data).format('DD/MM/YYYY')"></i>
                        <i class="pi pi-thumbs-down red" v-else-if="assinatura.status === 'REJEITADO'" v-tooltip.top="'Assinatura foi rejeitada em ' + $moment(assinatura.data).format('DD/MM/YYYY') "></i>
                        <i class="pi pi-minus yellow" v-else v-tooltip.top="'Assinatura pendente'"></i>
                    </div>
                </div>
            </div>
        </div>

        <template #footer>
            <Button label="Fechar" class="p-button-text" @click="closeDialog()" />
        </template>
    </Dialog>
</template>

<script>
import Services from '../../service.js';
import DialogAlterarAssinatura from './DialogAlterarAssinatura.vue';

export default {
    props: {
        parceria: {
            type: Object,
        },
    },

    components: {
        DialogAlterarAssinatura,
    },

    setup() {},

    data() {
        return {
            display: false,
            assinaturas: [],
        };
    },

    methods: {
        showDialog() {
            this.display = true;
            this.buscarAssinaturas();
        },

        closeDialog() {
            this.display = false;
            this.$emit('atualizarLista');
        },

        async rechecarStatusAssinaturas() {
            this.$store.dispatch('addRequest');
            try {
                const resposta = await Services.RechecarStatusAssinaturas(this.parceria.id);
                this.assinaturas = resposta?.data || [];
            } catch (error) {
                console.error(error);
                this.assinaturas = [];
            } finally {
                this.$store.dispatch('removeRequest');
            }
        },

        async buscarAssinaturas() {
            this.$store.dispatch('addRequest');
            try {
                const resposta = await Services.BuscarStatusAssinaturas(this.parceria.id);
                this.assinaturas = resposta?.data || [];
            } catch (error) {
                console.error(error);
                this.assinaturas = [];
            } finally {
                this.$store.dispatch('removeRequest');
            }
        },
    },
};
</script>

<style>
.two-columns-card {
    display: flex;
    flex-direction: row;
}
.first-column {
    flex: 9;
}
.second-column {
    align-self: center;
    flex: 1;
}
.green {
    color: yellowgreen;
}

.red {
    color: red;
}

.yellow {
    color: orange;
}

.titleWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.email-wrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}
</style>
