<template>
    <font-awesome-icon icon="comment" @click="showDialog()" size="lg" :class="defineCorBalao()" class="icon-button ml-2"
        :title="titulo" />
    <Dialog v-model:visible="display" no-close-on-backdrop modal :style="{ width: '50vw' }" :maximizable="false"
        :closable="false">
        <template #header>
            <h3>{{ parceria.numeroAcordo }} - {{ titulo }}</h3>
        </template>
        <erros-box :erros="erros"></erros-box>
        <div class="formgrid grid p-fluid">
            <div class="field col-12">
                <Textarea v-model="comentarios" :autoResize="true" rows="5" cols="30" :disabled="disable" />
            </div>
        </div>

        <template #footer>
            <Button label="Fechar" autofocus @click="closeDialog()" />
            <Button label="Salvar" autofocus @click="salvar()" :disabled="disable" />
        </template>
    </Dialog>
</template>

<script>
import Services from '../../service.js';

export default {
    props: {
        titulo: {
            type: String,
            default: 'Conclusões Finais'
        },
        isLicaoAprendida: {
            type: Boolean,
            default: false
        },
        parceria: {
            type: Object,
        },
    },

    components: {
    },

    setup() {
    },

    data() {
        return {
            display: false,
            disable: false,
            erros: [],
            comentarios: '',
        };
    },

    methods: {
        showDialog() {
            this.display = true
            this.comentarios = this.isLicaoAprendida ? this.parceria.licoesAprendidas : this.parceria.comentarioStatus;
            if (this.comentarios != null) {
                this.disable = true;
            }
        },

        closeDialog() {
            this.display = false;
            this.erros = [];
        },

        defineCorBalao() {
            if (this.parceria) {
                const status = this.parceria.status;
                if (status === 2) {
                return 'red-icon';
                } else if (status === 3) {
                return 'blue-icon';
                } else if (status === 4) {
                return 'yellow-icon';
                }
            }
            return '';
        },

        salvar() {
            this.$store.dispatch('addRequest');
            if (this.isLicaoAprendida) {
                Services.InserirLicoesAprendidasParceria({idParceria: this.parceria.id, licoesAprendidas: this.comentarios})
                    .then((response) => {
                        if (response && response.success) {
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Lições Aprendidas',
                                detail: 'Lições Aprendidas inseridas com sucesso',
                                life: 3000,
                            });
                            this.disable = true;
                            this.$emit('onSuccess');
                            this.closeDialog();
                        } else {
                            this.erros = response.errors;
                        }
                    })
                    .finally(() => {
                        this.$store.dispatch('removeRequest');
                    });
            } else {
                Services.SalvarComentarioStatusParceria({ idParceria: this.parceria.id, comentarioStatus: this.comentarios })
                    .then((response) => {
                        if (response && response.success) {
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Comentário do Status da Parceria',
                                detail: 'Comentário do Status da Parceria salvo com sucesso',
                                life: 3000,
                            });
                            this.disable = true;
                            this.$emit('onSuccess');
                            this.closeDialog();
                        } else {
                            this.erros = response.errors;
                        }
                    })
                    .finally(() => {
                        this.$store.dispatch('removeRequest');
                    });
            }
        },
    },
};
</script>

<style>
.red-icon {
  color: red;
}

.blue-icon {
  color: blue;
}

.yellow-icon {
  color: rgb(212, 198, 1);
}
</style>