<template>
    <font-awesome-icon
        icon="signature"
        @click="showDialog()"
        size="lg"
        style="color: red"
        class="icon-button ml-1"
        title="Enviar acordo para assinatura"
        v-if="parceria.status === 0 || parceria.status === 11"
    />
    <Dialog v-model:visible="display" no-close-on-backdrop modal :style="{ width: '500px' }" :maximizable="false" :closable="false">
        <template #header>
            <h3>Enviar acordo para assinatura?</h3>
        </template>
        <div class="grid p-fluid">
            <div class="col-3">
                <strong> Nº do Acordo: </strong>
            </div>
            <div class="col-9">
                {{ parceria.numeroAcordo }}
            </div>
            <div class="col-3">
                <strong> Assinantes: </strong>
            </div>
            <div class="col-9">
                <div v-for="assinante in assinantes" :key="assinante.id" class="card two-columns-card">
                    <div class="first-column">
                        <div class="card-title">
                            <strong>{{ assinante.cargo }}</strong>
                        </div>
                        <div v-if="!!assinante.nome">{{ assinante.nome }}</div>
                        <div>
                            {{ assinante.email }}
                        </div>
                    </div>
                    <div class="second-column">
                        <i class="pi pi-thumbs-up green" v-if="assinante.email && $validarEmail(assinante.email)"></i>
                        <i class="pi pi-thumbs-down red" v-else></i>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Fechar" class="p-button-text" @click="closeDialog()" />
            <Button label="Iniciar Assinatura" @click.stop="iniciarAssinatura()" :disabled="!podeAssinar" />
        </template>
    </Dialog>
</template>
<script>
import Services from '../../service.js';
export default {
    props: {
        parceria: {
            type: Object,
        },
    },
    components: {},
    setup() {},
    data() {
        return {
            display: false,
            assinantes: [],
            podeAssinar: false,
        };
    },
    methods: {
        showDialog() {
            this.display = true;
            this.buscarAssinantes();
        },
        closeDialog() {
            this.display = false;
        },
        checarAssinaturas() {
            const temAnalista = this.assinantes.some((assinante) => assinante.cargo === 'ANALISTA');
            const temGerente = this.assinantes.some((assinante) => assinante.cargo === 'GERENTE');
            const temTestemunha = this.assinantes.some((assinante) => assinante.cargo === 'Testemunha');
            const temCliente = this.assinantes.some((assinante) => assinante.cargo === 'Interlocutor');
            this.podeAssinar =
                this.assinantes.length > 0 &&
                this.assinantes.every((assinante) => assinante.email && this.$validarEmail(assinante.email)) &&
                temAnalista &&
                temGerente &&
                temTestemunha &&
                temCliente;
        },
        async buscarAssinantes() {
            this.$store.dispatch('addRequest');
            try {
                const assinantesResposta = await Services.BuscarAssinantes(this.parceria.id);
                this.assinantes = assinantesResposta?.data || [];
                this.checarAssinaturas();
            } catch (error) {
                console.error(error);
                this.assinantes = [];
            } finally {
                this.$store.dispatch('removeRequest');
            }
        },
        async iniciarAssinatura() {
            this.$store.dispatch('addRequest');
            try {
                await Services.EnviarAcordoParaAssinatura(this.parceria.id);
                this.$emit('onSuccess');
                this.closeDialog();
            } catch (error) {
                console.error(error);
            } finally {
                this.$store.dispatch('removeRequest');
            }
        },
    },
};
</script>
<style>
.two-columns-card {
    display: flex;
    flex-direction: row;
}
.first-column {
    flex: 9;
}
.second-column {
    align-self: center;
    flex: 1;
}
.green {
    color: green;
}
.red {
    color: red;
}
</style>
