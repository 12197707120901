<template>
    <font-awesome-icon icon="search" @click="abrir()" size="lg" class="icon-button ml-2" title="Visualizar Pdf" />
    <VisualizarPDFParceria
        :mostrar="mostrar"
        titulo="Acordo Parceria"
        @cancelar="fechar()"
        :erros="erros"
        >
        <iframe
            v-if="arquivo"
            v-bind:src="arquivo"
            style="width:100%; height: 850px;"
        ></iframe>
    </VisualizarPDFParceria>
</template>

<script>
import VisualizarPDFParceria from './VisualizarPDFParceria';
import Services from '../../service.js';

export default {
    components: {
        VisualizarPDFParceria,
    },

    props: {
        idParceria: {
            type: Number,
        },
    },

    data() {
        return {
            mostrar: false,
            arquivo: [],
        };
    },

    methods: {
        abrir() {
            this.imprimirPdf(this.idParceria)
            this.mostrar = true;
        },

        fechar() {
            if (this.mostrar) {
                this.arquivo = null;
                this.mostrar = false;
            }
        },

        imprimirPdf(idParceria) {
            this.$store.dispatch('addRequest');
            Services.BaixarDocumentoAssinadoOuDocumentoOriginal(idParceria).then((response) => {
                if (response) {
                    const blob = new Blob([response], {type: "application/pdf"});
                    this.arquivo = window.URL.createObjectURL(blob);
                    this.mostrar = true;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
