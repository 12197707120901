<template>
    <Dialog v-model:visible="display" :modal="true" :style="{ width:'80%', height:'80%'}" @hide="cancelar()" :contentStyle="{ overflow: 'visible' }">
        <template #header>
            <h5>{{ titulo }}</h5>
        </template>
        <div class="formgrid grid  p-fluid">
            <slot></slot>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary" @click="cancelar()" autofocus />
        </template>
    </Dialog>
</template>

<script>
export default {
    setup() {
    },

    props: {
        mostrar: {
            type: Boolean,
            default: false,
        },

        titulo: {
            type: String,
        },

        erros: {
            type: Array,
        },
    },


    data() {
        return {
            display: false,
        };
    },
    

    methods: {
        cancelar() {
            this.$emit('cancelar');
        },
    },

    watch: {
        mostrar() {
            this.display = this.mostrar;
        },
    },
};
</script>
